<template>
	<div class="home_page">
		<el-backtop :bottom="100">
			<div class="scoll_top" id="scollTop">
				<img src="../../assets/home_icons/top.png">
			</div>
		</el-backtop>

		<div class="top_fix">
			<div class="top">
				<loginHead></loginHead>
			</div>
			<div class="home_top">
				<homeTop></homeTop>
			</div>
		</div>
		<div class="main">
			<router-view></router-view>
		</div>
		<div class="bottom">
			<loginBottom></loginBottom>
		</div>
		<type-filter></type-filter>

	</div>
</template>

<script>
	import loginHead from '@/components/loginHead.vue';
	import homeTop from '@/components/homeTop.vue';
	import loginBottom from '@/components/loginBottom.vue';

	export default {
		components: {
			loginHead,
			homeTop,
			loginBottom
		},
		data() {
			return {
				scrollTop: '', //距离顶部距离
			}
		},

		methods: {

		}
	}
</script>

<style scoped="" lang="less">
	.top_fix {
		width: 100%;
		position: fixed;
		top: 0;
		z-index: 500;
	}

	.main {
		padding-top: 150px;
	}

	.bottom {}

	.scoll_top {
		position: fixed;
		bottom: 50px;
		right: 100px;

		img {
			width: 112px;
			height: 112px;
		}
	}

	/deep/ .el-backtop {
		background-color: transparent;
		width: 0;
		height: 0;
	}
</style>
