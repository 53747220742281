<template>
	<div class="all_home_top">
		<div class="home_top">
			<div class="menu" @click="typeClick">
				<img src="@/assets/home_icons/menu.png">
			</div>
			<div class="small_logo" @click="toHomePage">
				<img src="@/assets/login/small_logo.png">
			</div>
			<div class="search">
				<div class="search_left">
					<input type="text" :placeholder="$t('home.pleaseEnterKeyword')" v-model="search_name"
						@keyup.enter="searchClick" />
				</div>
				<div class="search_right" @click="searchClick">
					<img src="@/assets/home_icons/search.png">
				</div>
			</div>
			<div class="address">
				<!-- <div class="img">
					<img src="@/assets/home_icons/address.png">
				</div>
				<div class="address_name">hhhhhh新加坡</div>
				<i class="el-icon-arrow-down el-iconright"></i> -->
			</div>
			<div class="release" @click="toReleaseClick">
				<div class="img">
					<img src="@/assets/home_icons/release.png">
				</div>
				<div class="text">{{$t('other.release')}}</div>
			</div>
		</div>
	</div>

</template>

<script>
	import bus from '@/common/function/bus.js'

	export default {
		data() {
			return {
				search_name: '',
				isBindingModal: '', //是否完善信息
			}
		},
		mounted() {
			this.search_name = this.$route.query.search_name;
			bus.$on('personInforEmit', (data) => {
				this.getUserInfo();
			})
			this.getUserInfo();
		},
		methods: {
			// 获取会员信息
			getUserInfo() {
				this.$http.userInfo().then(res => {
					if (res.code == 1) {
						// 是否完善
						this.isBindingModal = res.data.authentication == 0 ? true : false;
					}
				})
			},
			// 去搜索
			searchClick() {
				// if (!this.search_name) {
				// 	this.$message.info(this.$t('tips.searchContent'));
				// 	return
				// }

				this.$router.push({
					path: '/searchResult',
					query: {
						search_name: this.search_name,
					}
				});
			},
			inputClick() {

			},
			typeClick() {
				this.$store.commit('changeShowFilter');

			},
			// 发布信息
			toReleaseClick() {
				if (!this.$store.state.userinfo) {
					this.$message.error(this.$t('other.noLoginPleaseToLogin'));
					setTimeout(() => {
						this.$router.push({
							path: "/accountLogin"
						});
					}, 1500)
					return;
				}

				if (this.isBindingModal) {
					this.$message.error(this.$t('completeInfor'));
					setTimeout(() => {
						this.$router.push({
							path: '/verifyPage',
							query: {
								curCenterIndex: 0,
							},
						})
					}, 1000)
					return;
				}

				this.$router.push('/release')
			},
			toHomePage() {
				this.$router.push('/')
			}
		}
	}
</script>

<style scoped="" lang="less">
	.all_home_top {
		background-color: #FFFFFF;
	}

	.home_top {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 28px 0;

	}

	.menu {
		cursor: pointer;

		img {
			width: 32px;
			height: 26px;
		}
	}

	.small_logo {
		cursor: pointer;

		img {
			width: 162px;
			height: 44px;
		}
	}

	.search {
		display: flex;
		align-items: center;
		width: 540px;
		height: 40px;
		border: 2px solid #E60012;
		opacity: 1;
		border-radius: 20px;
		box-sizing: border-box;
		position: relative;

		.search_left {
			width: 460px;
			text-align: left;
			padding-left: 24px;

			input {
				width: 400px;
				border: none;
				outline: none;
				background-color: transparent;
			}


			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none !important;
			}

		}

		.search_right {
			position: absolute;
			right: -2px;
			box-sizing: border-box;
			width: 82px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #E60012;
			border-top-right-radius: 20px;
			border-bottom-right-radius: 20px;

			img {
				width: 20px;
				height: 20px;
			}
		}
	}

	.address {
		display: flex;
		align-items: center;

		.img {
			display: flex;
			align-items: center;
			margin-right: 5px;

			img {
				width: 20px;
				height: 20px;
			}
		}

		.address_name {
			max-width: 90px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.release {
		width: 106px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 20px;
		background: #E60012;
		color: #FFFFFF;
		box-sizing: border-box;

		&:hover {
			cursor: pointer;
		}

		.img {
			margin-right: 5px;
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.text {
			font-size: 16px;
		}

	}
</style>
